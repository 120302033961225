@import './var'
@import './mixins'

.file-container
  margin-right: 10px

  .file-box
    height: 100px
    width: 100px
    border: 1px dashed #d2ddec
      radius: 0.375rem
    background: white
    margin-bottom: 10px
    position: relative
    overflow: hidden

    input
      cursor: pointer
      position: absolute
      top: 0
      right: 0
      bottom: 0
      left: 0
      opacity: 0
