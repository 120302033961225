@import './var'
@import './mixins'
@import './theme.scss'

.custom-pagination
  border-top: 2px solid #EDF2F9
  padding: 10px 0 15px

  button.pagination-item
    background: none
    padding: 0
    border: 1px solid transparent
      radius: 8px
    // margin: 0 2px
    width: 30px
    height: 30px
    font-size: 13px

    &--active
      background: $blue
      color: white
