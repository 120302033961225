// .rdw-editor-wrapper
//   margin:
//     bottom: 20px

//   .rdw-editor-main
//     background-color: #ffffff

//   .rdw-option-wrapper
//     border: none

//     img
      // width: 11px 

.product-icon-wysiwyg #icons.quill .ql-container.ql-snow .ql-editor img
  height: 14px
  width: 14px

.career-page-block-content 
  #wysiwyg-editor, #text-picture-wysiwyg-editor
    position: relative

    .ql-tooltip.ql-editing
      left: -23px !important


  #text-picture-wysiwyg-editor .ql-tooltip.ql-editing
    margin-top: -35px !important

  #wysiwyg-editor .ql-tooltip.ql-editing
    margin-top: -48px !important
    z-index: 1

  .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options
    background-color: #5d646b
