@import './mixins'
  
.select-client
  height: 100vh
  width: 100%
  display: flex
  min-height: 800px

  +md
    margin-top: 50px

  .container
    display: flex
    align-items: center
    justify-content: center
    flex-direction: column

    .card
      width: 100%
      max-width: 380px

      .card-header
        @apply bg-custom-gray-200

  .last-visited
    .card-body
      border-radius: .5rem
      overflow: hidden

      .list-group-item
        .logo,
        .name,
        .loader
          display: flex
          align-items: center

        .logo
          justify-content: center
          width: 10%

        .name
          justify-content: flex-start
          width: 50%

        .loader
          justify-content: flex-end
          width: 40%
          padding-right: 1rem

    p
      &.empty-list
        padding: 1rem
        text-align: center

  .welcome-note
    max-width: 500px
    text-align: center
    margin-bottom: 2rem
    display: flex
    justify-content: center
    align-items: center
    flex-wrap: wrap

    h1
      width: 100%
      font-size: 36px
      font-weight: 900
      margin-bottom: 30px

    p
      width: 60%

  .search-accounts
    .form-control
      border-radius: .75rem
