@import '../var'
@import '../mixins'

.comment-form
  padding: 0 50px
  margin-bottom: 50px

  +xs
    padding: 0

  +sm
    padding: 0

  .comment-form--text-area__control
    width: 100%
    margin-bottom: 10px
    resize: none
    min-height: 100px
    outline: none
    // padding: 7px 10px
    font-size: 13px
    border-radius: 5px

  &-single
    padding: 10px 0px
    margin-bottom: 10px