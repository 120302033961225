@import './var'

.css-w8afj7-Input
  margin: 0 !important

.custom-react-select
  &__control
    &.custom-react-select
      &__control
        border-radius: 0.375rem
        border-color: #d2ddec !important
        min-height: 30px !important
        // max-height: 30px !important
        align-items: stretch

    &:hover,
    &:focus,
    &:active,
    &:hover
      box-shadow: 0 0 0 1px #cccccc !important
      border-color: #d2ddec !important
  
  &__placeholder,
  &__single-value
    font-size: 0.8rem !important

  &__value-container,
  &__indicators
    // height: 30px !important

  &__indicator-separator
    display: none !important

  &__indicator
    &.custom-react-select
      &__dropdown-indicator
        padding: 8px 4px 8px 0

      &__clear-indicator
        padding: 8px 0
  
  &__multi-value
    border-radius: 50px !important

.custom-react-select__indicators
  height: 30px

// .focused
.custom-react-select
  &__control
    &.custom-react-select
      &__control
        &--menu-is-open
          border-bottom-left-radius: 0 
          border-bottom-right-radius: 0 

        &--is-focused
          border-color: #d2ddec !important

  &__menu
    margin-top: 0 !important
    border-top-width: 0 !important 
    border-top-left-radius: 0 !important 
    border-top-right-radius: 0 !important 
    border-bottom-right-radius: 0.375rem !important
    border-bottom-left-radius: 0.375rem !important
    font-size: 0.8rem !important
    overflow: auto

.custom-react-select-search
  &__value-container 
    padding-left: 12px !important
    cursor: pointer

  &__placeholder, &__single-value
    margin-left: 20px !important

  &__input
    input
      padding-left: 4px !important

  &__menu 
    position: relative !important
    box-shadow: none !important
    margin-bottom: 0 !important

.custom-react-normal-select
  display: block
  width: 100%
  border: 1px solid #D2DDEC
    radius: 6px
  padding: 5px 10px
  height: 38px
  outline: none

.select-general-settings
  background: #3a434e
  color: white
  border-color: transparent