/**
 * Dashkit v1.4.2
 *
 * Custom variables followed by Dashkit variables followed by Bootstrap variables
 * to ensure cascade of styles.
 */


// Colors
$primary-color: #68B1B6;
$primary-color-light: #DDEBED;
$bg-color: #F9FBFD;
$text-color: #000000;
$text-color-secondary: #71717A;
$text-color-light: #FFFFFF;
$accent-light-color: #F2F2F2;
$table-hoover-color: #F3F9F9;
$dropzone-bg: #E4E4E4;
$success-color: #62D686;
$error-color: #FF3134;
$warning-color: #FFE621;
$gray-badge: #DFE1E2;
$border-color: #DFE1E2;
$light-text: #F2F2F2;
$button-danger: #F3F9F9;
$button-danger-hover: #FF31341A;
$button-primary-outline-hover-bg: #f3f9f9;
$popover-gray-bg: #C9CACC;
$popover-gray-text: #4C4f56;
$checkbox-accent-color: #539a9f;
$body-background: #fafafb;
$upload-gray: #E4E4E4;

:root {
    --primary-color: #{$primary-color};
    --primary-color-light: #{$primary-color-light};
    --bg-color: #{$bg-color};
    --text-color: #{$text-color};
    --text-color-secondary: #{$text-color-secondary};
    --accent-light-color: #{$accent-light-color};
    --dropzone-bg: #{$dropzone-bg};
    --table-hoover-color: #{$table-hoover-color};
    --success-color: #{$success-color};
    --error-color: #{$error-color};
    --warning-color: #{$warning-color};
    --gray-badge: #{$gray-badge};
    --button-danger: #{$button-danger};
    --button-danger-hover: #{$button-danger-hover};
    --button-primary-outline-hover-bg: #{$button-primary-outline-hover-bg};
    --checkbox-accent-color: #{$checkbox-accent-color};
    --body-bg: #{$body-background};
    --upload-gray: #{$upload-gray};
}

// Bootstrap functions
@import 'bootstrap/scss/functions';

// Dashkit variables
@import 'dashkit/variables';

// Custom variables
@import 'user-variables';

// Bootstrap core
@import 'bootstrap/scss/bootstrap';

// Dashkit core
@import 'dashkit/dashkit';

// Custom core
@import 'user';

body {
    background-color: $body-background !important;
}