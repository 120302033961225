
.card-container
    display: flex
    flex-direction: row
    justify-content: center
    flex-flow: wrap

.test-card
    border: 1px solid #ccc
    position: relative
    width: 170px
    min-height: 200px
    border-radius: 5px
    padding: 15px
    margin: 0.500rem

    h4
        text-align: center

.test-card-not-complete
    text-align: center
    margin-top: 30px

.test-card-reminder
    text-align: center
    margin-top: 20px
    font-size: 0.7rem

.test-card-date
    color: #c1c1c1
    font-size: 10px

.test-card-score
    text-align: center
    font-size: 5rem

.score-yellow
    color: #e0de6e

.score-red
    color: #e94a4a

.score-green
    color: #75bc74

p
    .no-results.text
        font-style: italic
        margin: 14px

.failed-result
    position: absolute
    top: 0
    right: 0
    bottom: 0
    left: 0
    padding: 0 20px

