.ts-table-container
  @apply border-t border-medium-gray

tr
  @apply border-b border-medium-gray

th, td
  @apply px-6 whitespace-nowrap bg-white overflow-hidden

  &.column-sticky
    @apply sticky right-0 py-1

    &:before
      content: ''
      width: 1px
      top: 0
      bottom: 0
      left: 0
      background: rgb(225, 226, 226)
      @apply absolute

td
  font-size: .75rem
  @apply py-2.5

  &.column-sticky
    background: #fff !important
    display: table-cell

th
  height: 46.5px
  @apply bg-body text-secondary uppercase whitespace-nowrap

th, th button
  font-size: 0.625rem
  letter-spacing: 0.08em !important

tr:not(.table-headers):hover td
  background: var(--table-hoover-color)
