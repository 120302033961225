@import './var'
@import './mixins'
@import './theme.scss'

.form-view
  .list-group-item
    padding: 10px 0 !important

.form-sections
  min-height: 70vh

  input,
  .custom-select,
  .form-control:not(textarea)
    border-radius: 8px

  ::-webkit-inner-spin-button,
  ::-webkit-outer-spin-button
    -webkit-appearance: none
    margin: 0
    -moz-appearance: test-field

  .form-control.is-invalid
    background-image: none
    padding-right: .75rem

  .switch-placeholder
    box-shadow: none

    span
      background: #fff

    &.switch-placeholder
      &--active
        background: #18375F

  .custom-wysiwyg
    margin-bottom: 2rem

    .ql-toolbar
      display: flex
      align-items: flex-end
      padding-bottom: .75rem
      background-color: #fff !important
      border: 1px solid #d8e1ed !important
      border-radius: 8px !important
      margin-bottom: -4px !important
      border-bottom-right-radius: 0 !important
      border-bottom-left-radius: 0 !important
      border: 1px solid #d8e1ed !important
      border-radius: 8px !important

    .ql-editor
      background-color: #fff !important
      border: none !important
      color: #000 !important
      border-top-right-radius: 0 !important
      border-top-left-radius: 0 !important
      overflow: auto
      resize: vertical

    .ql-picker-label
      padding-left: 0

  .list-group-item
    padding: 10px !important

.admin-modal
  &-user
    &-avatar
      margin:
        left: auto
        right: auto
        bottom: 20px

    &-avatar:hover
      cursor: pointer

.avatar-wrapper
  display: flex
  justify-content: center

.image-upload-bg
  height: 200px
  background-repeat: no-repeat
  background-size: 90%
  background-color: #fff
  background-position: center
  border-radius: 20px
  cursor: pointer

.invalid-field
  width: 100%
  margin-top: 0.25rem
  font-size: 0.8125rem
  color: #CD4616

.avatar-select
  display: flex
  align-items: center

  .avatar-group
    .avatar
      &:last-child
        margin:
          right: 1rem

  .btn-rounded-circle
    line-height: 2
    margin:
      left: 10px

  .plus-icon
    position: absolute
    z-index: 10
    left: 0
    color: #d2ddec
    width: 40px
    text-align: center

  .has-icon-before
    position: relative
    display: flex
    align-items: center

    input
      border-radius: 20px !important

  .rbt
    .rbt-input
      width: 40px
      transition: all .25s
      padding: 0
      color: #fff

      &::-webkit-input-placeholder,
      &::placeholder
        opacity: 0

    .rbt-input-main
      &::-webkit-input-placeholder,
      &::placeholder
        opacity: 0

    .rbt-token
      &.rbt-token-removeable
        background-color: #fff

    .rbt-input-multi
      padding:
        left: 0
      &.rbt-input
        border-radius: 20px

    &.focused
      .rbt-input-hint-container
        input
          border-radius: 8px !important

      .rbt-input
        width: 100%
        color: unset

        &::-webkit-input-placeholder,
        &::placeholder
          opacity: 1

      .rbt-input-main
        &::-webkit-input-placeholder,
        &::placeholder
          opacity: 1

      .rbt-input-multi
        .rbt-input-main
          padding-top: .6rem !important
          padding-right: 1rem !important

        &.rbt-input
          border-radius: 8px

        .rbt-token
          &.rbt-token-removeable
            background-color: #18375F

    &.not-empty
      .rbt-input-multi
        padding:
          left: 40px

.external-link
  .disabled
    label,
    .switch-placeholder
      opacity: .5

    input
      background-color: #f5f6f7 !important

    .rbt-input-hint
      display: none !important

  span
    margin:
      left: .75rem

.additional-info
  margin:
    left: .25rem

h3
  &.line-behind
    position: relative
    overflow: hidden
    color: var(--text-color-secondary)
    text-transform: uppercase
    font-size: .85em
    font-weight: 100
    margin:
      top: 2rem
      bottom: 1.75rem

    &:after
      content: ''
      border-top: 1px solid $border-color
      position: absolute
      top: 50%
      width: 100%
      margin:
        left: 10px

    .line-behind-icon
      position: absolute
      right: 0
      top: -6px
      background: #f5f6f7
      z-index: 2
      width: 24px
      cursor: pointer

.line-behind-title
  position: relative
  overflow: hidden
  color: var(--text-color-secondary)
  text-transform: uppercase
  font-size: .85em
  font-weight: 100
  margin:
    top: 2rem
    bottom: 1.75rem

  &:after
    content: ''
    border-top: 1px solid $border-color
    position: absolute
    top: 50%
    width: 100%
    margin:
      left: 10px

  .line-behind-icon
    position: absolute
    right: 0
    top: -6px
    background: #f5f6f7
    z-index: 2
    width: 24px
    cursor: pointer

.typeahead-icon
  position: absolute
  top: 38px
  color: $border-color

.has-icon
  &-before
    input
      padding:
        left: 2rem !important

    .typeahead-icon
      left: 24px

  &-after
    input
      padding:
        right: 2rem !important

    .typeahead-icon
      right: 24px

.question-adder
  border: 1px dashed $border-color
  padding: .5rem 1rem
  cursor: pointer
  border-radius: 0.375rem

  .faded
    @apply flex items-center

  p
    margin-bottom: 0

  svg
    margin:
      right: 5px

.faded
  color: $text-color-secondary
  font-size: .8rem

.category-list
  margin-top: 2rem

  li
    &.list-group-item
      display: flex
      justify-content: flex-start
      align-items: center
      margin:
        top: 1rem
      border-radius: .5rem
      border-bottom:
        left-radius: 0
        right-radius: 0
      padding: 1rem 2rem !important

  .card
    border-top: 0
    border-top:
      left-radius: 0
      right-radius: 0

  .switch
    margin-left: 1rem

  .custom-badge-wrapper
    min-width: 220px
    display: flex
    align-items: center

  .icon-wrapper
    flex-grow: 1
    display: flex
    justify-content: flex-end
    cursor: pointer
    svg
      margin: 1rem

  .category-questions-list
    margin:
      top: 1rem
      bottom: 1rem

  .form-group
    .switch
      margin:
        left: 0

.custom-badge
  padding: .2rem 1rem
  color: $text-color
  border-radius: 1rem
  min-width: 3rem
  margin:
    right: 1rem
  text-align: center

.sticky-bottom-menu
  position: fixed
  bottom: 0
  margin:
    left: -30px
    right: -30px
  border-top: 1px solid $border-color
  padding: 1rem 2rem
  background-color: $text-color-light
  width: calc(100% - 220px)
  min-height: 70px
  transition: width 0.15s linear

  +xs
    width: 100%

  ol
    margin-bottom: 0

    li
      position: relative
      cursor: pointer

      &:not(:last-of-type)
        margin-right: 55px

        &:after
          position: absolute
          content: ''
          border-top: 1px solid $border-color
          top: 50%
          left: 100%
          max-width: 2rem
          width: 50%
          margin-left: .5rem

          +xs
            border-color: transparent !important

          +sm
            border-color: transparent !important

      .active
        span
          &.number,
          &.label
            color: $text-color

          path
            color: $text-color

          &:after
            border-color: $text-color

      div
        svg
          margin:
            right: 5px

        span
          color: $text-color-secondary

.sidebar-menu--minimized + .right-content
  .sticky-bottom-menu
    width: calc(100% - 60px)

.question-item
  &-icons
    svg
      cursor: pointer

.promote-position
  .product-button
    &:hover
      background-color: #34d470
      color: #fff !important

@media (max-width: $breakpoint-md)
  .sticky-bottom-menu
    &-container
      flex-direction: column

      ol
        flex-direction: column
        margin: 10px

@media (max-width: $breakpoint-lg)
  .sticky-bottom-menu
    &-container
      ol
        li
          &:after
            display: none

.job-function
  .form-control
    &.rbt-input-multi
      &.rbt-input
        background-color: #f5f6f7
        border-radius: 8px
