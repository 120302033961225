.ql-snow
  overflow: visible !important
  .ql-tooltip
    .ql-action::before
      content: none !important

    &.is-invalid
      .ql-toolbar,
      .ql-container
        &.ql-snow
          border-color: #f8bac3 !important

    .ql-picker-options
      .ql-picker-item
        white-space: initial
        max-width: 120px


.ql-editor 
  & ol, 
  & ul
    word-break: break-all