@import './var'
@import './mixins'
@import './theme.scss'

@keyframes menuHovered
  from 
    opacity: 0
    transform: translateX(0)

  to
    opacity: 1
    transform: translateX(5px)

.sidebar-menu-overlay
  position: fixed
  top: 0
  bottom: 0
  left: 0
  right: 0
  background: rgba(0, 0, 0, 0.2)
  z-index: 9

.sidebar-menu
  @apply bg-white
  border-right: 1px solid $border-color
  width: 220px
  position: fixed
  top: 0
  bottom: 0
  left: 0
  z-index: 25
  max-height: 100vh
  transition: all 0.15s linear

  +xs
    top: 70px

  .btn-menu-container
    position: absolute
    width: 100%
    bottom: 0

    +xs
      display: none
  
  button.btn.btn-primary.btn-menu
    position: absolute
    right: -13px
    height: 28px
    width: 28px
    bottom: 38px
    background-color: $primary-color !important
    border: 1px solid white !important
    transition: all 0.15s linear

    &:hover
      background-color: $primary-color !important

    &--minimized
      transform: scale(1.1)
      right: 16px
      bottom: 20px

      svg
        left: 1px !important

    svg
      fill: white
      color: white
      font-size: 13px
      margin-right: 0
      position: relative
      left: -1px
      margin-right: 0 !important

    span
      color: white
      font:
        size: 13px
        weight: 500
      text-transform: uppercase
      letter-spacing: 1px
      margin-left: 8px

    .arrow
      .arrow-bar
        display: block
        height: 2px
        width: 22px
        margin: 5px 0
        background: white
        transition: all 0.15s ease-in-out

    &:hover .arrow
      &-bar:nth-child(1)
        transform: translate(-7px ,3px) rotate(-45deg) scaleX(0.6)

      &-bar:nth-child(3)
        transform: translate(-7px, -3px) rotate(45deg) scaleX(0.6)

      &--minimized
        .arrow-bar:nth-child(1)
          transform: translate(7px ,3px) rotate(45deg) scaleX(0.6)

        .arrow-bar:nth-child(3)
          transform: translate(7px, -3px) rotate(-45deg) scaleX(0.6)

  &::-webkit-scrollbar-thumb
    background: rgba(255,255,255,0.6)
    border-radius: 0

  &::-webkit-scrollbar-thumb:hover 
    background: rgba(255,255,255,0.9)

  +xs
    left: -270px
    transition: left 0.15s

    &--active
      left: 0

  .navbar-menu
    flex-grow: 1
    width: 100%
    display: flex
    flex-direction: column
    padding-bottom: 20px
    transition: all 0.15s linear
    overflow:
      y: auto
      x: hidden

    +xs
      padding-bottom: 0

  .navbar-logo
    height: 100px
    width: calc(100% - 30px)
    display: flex
    align-items: center
    justify-content: center
    margin: 30px auto 20px
    transition: 0.15s margin linear, 0.15s width 0.3s linear

    .logo
      height: 100%

  .nav-item
    position: relative

    .dropdown-item:hover
      background: transparent !important

    &-button
      display: flex
      align-items: center
      background: none
      border: none
      outline: none
      padding:
        left: 27px
        right: 30px
      color: $text-color-secondary !important
      height: 40px
      width: 100%
      text-align: left
      font-weight: 400
      border-left: 3px solid transparent

      &:hover
        border-color: $primary-color !important
        color: $text-color !important

      .animated-label
        opacity: 0
        transform: translateX(0)
        position: fixed
        left: 70px
        margin-top: 2px
        background: white
        padding: 8px 10px
        font:
          size: 12px
          weight: 700
        text-transform: uppercase
        border-radius: 5px
        color: black
        box-shadow: 0 1px 10px rgba(0, 0, 0, 0.05)
        letter-spacing: 1px
        animation: menuHovered 0.15s linear forwards

        &:after
          content: ''
          position: absolute
          top: 11px
          left: -7px
          width: 0
          height: 0 
          border-top: 7px solid transparent
          border-bottom: 7px solid transparent 
          border-right: 7px solid white
          box-shadow: 0 1px 10px rgba(0, 0, 0, 0.05)

      &--hovered
        .animated-label
          opacity: 1
          transform: translateX(5px)

      &--active
        border-color: $primary-color !important
        // background: #112643
        // border-top-right-radius: 50px;
        // border-bottom-right-radius: 50px

        span
          color: $text-color !important
          font-weight: bold

      span.icon
        display: inline-block
        width: 30px

      &-label
        margin: 0
        flex: 1

      svg
        margin-top: -1px

  button.btn.btn-primary
    border: none !important
      radius: 50px !important
    background-color: $mb-bg !important
    color: $mb-text-color !important
    font-weight: 900 !important
    width: calc(100% - 60px)
    margin: 0 auto

    &:hover
      background-color: lighten($mb-bg, 5%) !important

  &--minimized
    width: 60px

    .navbar-logo
      width: 100%
      margin: 3px 0 13px
      transition: 0.15s margin 0.25s linear, width 0.15s linear

      img.logo
        height: auto
        width: 40px

    span.nav-item-button-label,
    span.nav-item-button-label + svg
      display: none !important

    .nav-item
      position: relative

      &-button
        padding:
          left: 7px
          right: 0
        width: 100%
        display: flex
        justify-content: center

        &:hover + div .dropdown-item
          display: block !important

        .icon svg
          font-size: 16px
      
      .dropdown-item
        padding: 0
        left: 60px
        margin-top: -35px
        padding: 8px 8px 4px
        width: auto
        min-width: 140px
        position: fixed
        background: white
        z-index: 2000
        border-radius: 6px
        display: none

        &:hover
          background: white !important
          display: block !important

        li
          padding-left: 0px
          display: flex
          align-items: center

          span
            color: $blue

          span.nav-item-button-label
            display: inline-flex !important

          &.small-title
            padding-bottom: 3px
            border-bottom: 1px solid #e4e4e4
            margin-bottom: 4px
    
    .btn-promote-job
      display: none

    ul.dropdown-item 
      box-shadow: 0 0 15px rgba(0, 0, 0, 0.15)

      &:hover
        box-shadow: 0 0 25px rgba(0, 0, 0, 0.4)
      
      li a.nav-item-button--active span
        color: black !important
        font-weight: 600

  ul.dropdown-item
    padding-top: 0
    transform: translateX(10px)
    position: relative
    width: calc(100% - 20px)
    transition: all 0.15s linear

    &:before
      content: ''
      position: absolute
      top: 0
      bottom: 0
      width: 20px
      height: 100%
      left: -20px

    li
      padding-left: 26px
      line-height: 1.8

      a
        color: $text-color-secondary !important
        font-size: 14px

        span.icon
          width: 24px
          margin-right: 7px
          display: inline-flex
          justify-content: center

          svg
            font-size: 12px

      &:hover a
        color: $text-color !important

.menu-client
  padding: 0 25px 35px

  p
    font-size: 13px
    color: $text-color-secondary

  &-name
    background: $accent-light-color
    border-radius: 6px
    padding: 3px 10px
    color: $black
    font-weight: 600
    max-width: 100px
    word-break: break-word
    hyphens: auto
    -webkit-hyphens: auto
    -moz-hyphens: auto
    -ms-hyphens: auto
    text-align: center