@import './var'
@import './mixins'
@import './theme'

.login-container
  display: flex
  align-items: center
  justify-content: center
  flex-direction: column
  height: 100vh
  width: 100%
  background:
    size: cover
    position: center
    color: white

  .sign-in
    width: 100%
    min-height: 300px
    max-height: 400px
    padding: 0 20px
    height: 50%
    background: none
    display: flex
    flex-direction: column

    +xs
      background: rgba(white, 0.8)

    .form
      flex: 1
      display: flex
      align-items: center
      justify-content: center
      flex-direction: column

      .input
        max-width: 320px
        width: 100%

        .col-12
          .form-group
            margin-bottom: 15px

          &:last-of-type .form-group
            margin-bottom: 20px

        button, input
          display: block
          margin: auto
          width: 100%
          height: 2.2rem
          border: none
          radius: 30px
          font-size: 16px !important
          transition: 0.2s border

        button
          color: white
          margin-bottom: 15px
          border-radius: 4rem

          img
            &.button-loader
              top: 2px !important

        input
          padding: 0 20px
          border: 1px solid $border-color
          background:
            color: white !important
          outline: none
          // margin-bottom: 20px

          &.is-invalid
            border-color: $error-color

          &::placeholder
            color: $text-color-secondary

          &:hover
            border-color: darken($border-color, 20%)

          &:focus
            border-color: $sb-bg

        a
          font-size: 14px !important
          color: $text-color-secondary !important
          transition: 0.2s all

          &:hover
            color: darken($text-color-secondary, 30%)

      p
        font:
          size: 40px
          weight: 600
        color: $sb-bg
        margin-bottom: 30px

  .logo
    width: 180px
    overflow: hidden
    display: inline-block
    min-height: 72px
    margin: 10px auto 90px
