@import './theme.scss'

.candidates-nav-container
  overflow-x: auto
  border-top:
    right-radius: 0.5rem
    left-radius: 0.5rem

  .candidates-tab
    border: none
    display: table !important
    width: 100%
    position: relative
    overflow: hidden

    .nav-item
      margin: 0 !important
      display: table-cell
      vertical-align: middle
      background: rgba(0, 0, 0, 0.03)
      transition: all 0.15s

      &--active
        background: white

      &:not(:first-child)
        border-left: 1px solid #e3ebf6

      .nav-link
        padding: 12px 15px
        border-bottom: none !important
        white-space: nowrap

        &.active
          border-color: $success

        .badge
          margin-left: 10px

.slider
  background: #dedbdb
  height: 8px
  border-bottom:
    left-radius: 0.5rem
    right-radius: 0.5rem
  overflow: hidden

  .thumb
    height: 100%
    background: $primary
    width: 0
    transition: width 0.2s

.candidates-list--card
  // overflow: hidden
  margin-bottom: 0 !important

  .card-body--list-item
    // height: calc(100% - 110px)
    overflow: auto

.candidate
  &-select
    .custom-react-select
      &__control
        border-radius: 8px
        padding: .25rem .35rem
  &-date
    border-radius: 0.375rem !important

.timeline-show-more
  background: none
  border: none
  margin:
    top: 30px
    left: 40px
  padding: 0
  color: $blue
  font:
    size: 14px
    weight: 600

.dob-clicker
  height: 22px
  cursor: text
  line-height: 22px

[contenteditable=true]:empty:before, .input-placeholder
  content: attr(placeholder)
  color: rgba(0, 0, 0, 0.4)
  font:
    size: 12px
    weight: 500

.reject-candidate
  &-btn
    &:hover
      background-color: #dd4b39

.linked-positions
  display: flex
  align-items: center
  margin: auto
  justify-content: center
  margin:
    right: 5px
  font:
    size: 13px
  cursor: pointer
  border: 1px solid #dedbdb
  background-color: #f5f6f7
  padding: 2px 8px

  &-badge
    margin:
      left: 4px

.candidate-card-view-buttons
  height: 38px

  button
    padding: 2px 20px
    height: 38px
    display: flex
    align-items: center
    justify-content: flext-start
    border-radius: 42px

.candidate-more-action-button
  position: relative
  top: 3px
