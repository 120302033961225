@import 'react-datepicker/dist/react-datepicker.css'

.react-datepicker-wrapper
  width: 100%

.react-datepicker__input-container
  input
    width: 100%
    font-size: 0.8rem !important
    border-radius: 100px
    padding: .375rem .900rem
    height: 38px
    outline: none
    border: 1px solid var(--gray-badge)

    &:focus
      border-color: #18375F
      border-width: 1px

.header-text-container
  .layout-datepicker
    svg
      margin-right: 10px

    .react-datepicker__input-container
      input
        width: 72px
        background: none
        height: auto
        padding: 0 !important
        border: none

    span
      margin: 0 10px
