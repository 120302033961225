.ts-dropdown-overlay
  z-index: 999999 !important

.rc-dropdown-menu
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05) !important
  border: none !important
  border-radius: 0.5rem !important
  overflow: hidden !important

  &-item
    cursor: pointer
    background-color: white !important

    &:hover
      background-color: #f4f4f5 !important

    &.rc-dropdown-menu-item-selected
      &:after
        display: none