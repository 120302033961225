.profile-page
  .header-text-container
    // .d-flex.align-items-center.justify-content-between
    border-bottom: none !important
    padding-bottom: 0 !important
    margin-bottom: 0 !important

  .header-title
    text-transform: capitalize

.nav-tabs
  margin-bottom: 32px

  .nav-item
    a
      margin:
        right: 10px

.user-profile-form
  .tab-content
    padding:
      top: 2rem
      bottom: 2rem

  .form-group
    label
      text-transform: capitalize
      font-size: 1em
      letter-spacing: unset
      color: #000000

  .avatar-container
    cursor: pointer

  label
    font-size: 1em
    text-transform: capitalize
    color: #000000

.userprofile-wysiwyg
  .ql-container
    border: 1px solid #ccc !important
    border-top: 1px solid transparent !important
    border-top:
      left-radius: 0 !important
      right-radius: 0 !important
    border-bottom:
      left-radius: 5px !important
      right-radius: 5px !important
    margin-top: -1px

  .ql-editor
    background-color: #fff !important
    color: #000000 !important
    border: none !important

#userprofile-toolbar
  background-color: #ececec!important
  border-color: #ccc !important
  margin-bottom: 0 !important
  border-top-left-radius: 5px !important
  border-top-right-radius: 5px !important

  button
    background-color: #ececec !important
