.screening-questions
  &-previewer
    border: 1px solid #d2ddec
    background-color: #fff
    border-radius: 20px
    padding: 1rem
    margin:
      top: 1rem
      bottom: 1rem

  .switch 
    margin: 
      top: 1rem
      bottom: 1rem
