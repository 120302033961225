.screening
  margin: 0

  &-motivation
    margin-bottom: 20px

    h2
      color: var(--text-color)
      font-size: 1.2rem
      font-weight: 400
      margin-bottom: 12px

    &-content
      color: var(--text-color)
      line-height: 20px
      text-indent: 1.5em

    &-empty-response
      font-style: italic
      font-size: 0.7rem

  &-table
    display: table
    width: 60%

    &-body
      display: table-row-group

      p
        color: var(--text-color)

    &-row
      display: table-row

      &-cell
        background-color: #ffffff
        padding: 3px
        display: table-cell

        .slider
          background-color: #ffffff

        .answer
          font-weight: bold
