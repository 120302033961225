@import './var'
@import './mixins'

.topbar
  padding: 0 30px
  min-height: 70px
  max-height: 70px
  display: flex
  align-items: center
  justify-content: space-between
  z-index: 200
  transition: width 0.15s 

  +xs
    padding: 0 15px
    left: 0
    width: 100% !important

  .dropdown-item
    .icon-container
      margin-right: 5px

  &-menu, &-input
    flex: 1 1
    height: 100%
    position: relative

  &-input
    svg[data-icon="search"]
      position: absolute
      top: 50%
      transform: translateY(-50%)

    &-loader
      width: 30px
      height: 30px
      position: absolute
      left: -8px
      top: 19px  

    input
      width: 100%
      height: 100%
      border: none
      outline: none
      padding-left: 25px

    .search-results
      position: absolute
      top: 55px
      background: white
      border: 1px solid #dadee2
        radius: 0.5rem
      max-width: 300px
      width: 100%

      li
        padding: 10px 15px
        cursor: pointer

        &:not(:first-child)
          border-top: 1px solid #dadee2

        svg
          width: 30px
          margin-right: 5px

    *
      display: none

  .right-control
    display: flex
    align-items: center

    .dropdown
      margin-left: 17px

      .dropdown-item
        font-size: 13px !important

      svg
        margin-top: 5px

.dropdown-link.dropdown-header
  padding: 0 !important

  a
    padding: 5px 10px
    color: black !important
    display: block
    width: 100%

    .icon-container
      position: relative
      top: 2px

.cart
  &-badge
    position: absolute
    top: -10px
    left: 5px
    font-size: .5rem !important
    height: 16px !important
    padding: 0 7px !important
    line-height: 16px !important
    
  &-header
    font-size: 1rem

  &-summary
    font-size: .8rem
    padding: 10px

    p
      padding: 4px 10px

  &-item
    margin:
      top: 8px
      bottom: 8px

    &-title
      font-size: 1rem

    &-title
      &-text
        max-width: 95px

    &-description
      max-width: 154px

    &-title-text,
    &-description
      overflow: hidden
      white-space: nowrap
      text-overflow: ellipsis

  &-total
    font-size: .8rem

.cart-popover
  &.dropdown-menu
    min-width: 14rem !important
    padding: 8px
  
  button
    &.dropdown-item
      &:hover 
        background-color: #fff

.icon-container
  width: 22px
  display: inline-flex

#topbar-portal
  position: absolute
  top: 0
  left: 0
  bottom: 0
  right: 0
  padding: 0 30px
  background: white
  display: none

.topbar-position
  button
    background-color: #1e3580
    border-color: #1e3580
    color: #fff