@import '../var'
@import '../mixins'
@import '../theme.scss'

.email-form
  padding: 0 50px
  margin-bottom: 50px

  +xs
    padding: 0

  +sm
    padding: 0

  input.form-control
    border-radius: 5px
    margin-bottom: 15px

  a
    color: #006fff
    font-weight: bold

  .email-details
    margin-bottom: 40px

  .email-content
    margin-bottom: 20px

  .button-container
    margin-top: 17px

