.range-slider
  padding-bottom: 0.5rem
  margin: 1rem
  min-width: 200px

  &.has-sub-text
    margin:
      left: 1rem
      right: 1rem

  .input-range__slider 
    border-radius: 2rem
    width: 2rem

