@import './var'
  
=xs
  @media only screen and (max-width: $breakpoint-sm - 1)
    @content

=sm
  @media only screen and (min-width: $breakpoint-sm) and (max-width: $breakpoint-md - 1)
    @content

=md
  @media only screen and (min-width: $breakpoint-md) and (max-width: $breakpoint-lg - 1)
    @content

=lg
  @media (min-width: $breakpoint-lg)
    @content