// @import '../theme-dark.css'
@import '../../static/fonts/feather/feather.css'
@import './var'
@import './mixins'
@import './theme.scss'
@import './animation'
@import "tailwindcss/base"
@import "tailwindcss/components"
@import "tailwindcss/utilities"

body
  font-family: 'Cerebri Sans', sans-serif

pre, pre *, .badge-mention
  font-family: 'Cerebri Sans', sans-serif !important

.cursor
  &-pointer
    cursor: pointer

  &-alias
    cursor: alias

.card,
.accordion,
.accrodion-content,
.table
  &--no-padding
    padding: 0

  &--no-padding-top
    padding-top: 0

  &--no-padding-right
    padding-right: 0

  &--no-padding-bottom
    padding-bottom: 0

  &--no-padding-left
    padding-left: 0

  // margin
  &--no-margin
    margin: 0

  &--no-margin-top
    margin-top: 0

  &--no-margin-right
    margin-right: 0

  &--no-margin-bottom
    margin-bottom: 0

  &--no-margin-left
    margin-left: 0

  // borders
  &--no-border
    border: none

  &--transparent-border-top
    border-top-color: transparent

  &--transparent-border-right
    border-right-color: transparent

  &--transparent-border-bottom
    border-bottom-color: transparent

  &--transparent-border-left
    border-left-color: transparent

// display
.block
  display: block

.inline-block
  display: inline-block

.flex
  display: flex

.inline-flex
  display: inline-flex

// flex-box
.flex, .inline-flex
  &-jc
    &--flex-start
      justify-content: flex-start

    &--flex-end
      justify-content: flex-end

    &--center
      justify-content: center

    &--space-around
      justify-content: space-around

    &--space-between
      justify-content: space-between

  &-ai
    &--flex-start
      align-items: flex-start

    &--flex-end
      align-items: flex-end

    &--center
      align-items: center

    &--space-around
      align-items: space-around

    &--space-between
      align-items: space-between

  &-fw--wrap
    flex-wrap: wrap

  &-fd
    &--column
      flex-direction: column

    &--row
      flex-direction: row

.text
  &--left
    text-align: left

  &--right
    text-align: right

  &--center
    text-align: center

  &-transform
    &--uppercase
      text-transform: uppercase

    &--lowercase
      text-transform: lowercase

    &--none
      text-transform: none

.cursor--pointer
  cursor: pointer

.position
  &--relative
    position: relative

  &--absolute
    position: absolute

  &--fixed
    position: fixed

  &--sticky
    position: sticky

  &--static
    position: static

  &--stretch
    height: 100%
    width: 100%
    top: 0
    right: 0
    bottom: 0
    left: 0

::-webkit-input-placeholder,
::placeholder
  color: #dadada

::-webkit-scrollbar
  width: 7px
  height: 7px

::-webkit-scrollbar-track
  background: rgba($light-text, 0.4)

::-webkit-scrollbar-thumb
  background: $gray-badge
  border-radius: 5px

::-webkit-scrollbar-thumb:hover
  background: darken($primary-color, 10%)

body
  background-color: $main-bg

.nav-tabs .nav-item
  cursor: pointer
  margin:
    left: 0 !important
    right: 0 !important
  font-size: 14px

.dropdown
  cursor: pointer

.dropdown-menu
  padding: 0
  border: none
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1)
  transition: box-shadow 0.15s linear

  &:hover
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.22)

  &.show
    overflow: hidden

  &--no-padding
    padding: 0 !important

  .dropdown-header, .dropdown-item
    padding:
      left: 10px
      right: 10px

  .dropdown-divider
    border-color: #f3f3f3

button.dropdown-item[role="menuitem"]
  padding: 5px 10px

.dropdown-item.disabled, .dropdown-item:disabled
  padding: 5px 10px
  color: #adadad !important

.right-content
  margin-left: 220px
  height: 100vh
  transition: all 0.15s linear
  display: flex
  flex-direction: column
  position: relative
  z-index: 20

  &--minimized
    margin-left: 60px

  +xs
    margin-left: 0
    padding-top: 75px

  .main-content
    padding: 30px 30px 100px
    height: 100%
    display: flex
    flex-direction: column

    +xs
      padding: 15px 15px 100px

    &--full
      padding: 0

    .header-text-container
      padding-bottom: 8px

      +xs
        flex-direction: column
        align-items: flex-start !important

      .header-text
        margin-bottom: 5px

  .header-right-content
    .input-file
      position: relative
      overflow: hidden

      input
        position: absolute
        top: -100px
        left: 0
        right: 0
        bottom: 0
        opacity: 0
        height: 200px
        cursor: pointer

    button.btn
      border-radius: 100px !important

.dropdown-divider
  margin: 0 !important

.bg-primary
  color: white

.test-error-container
  background: #CD4616
  border: 1px solid #EDF2F9
  radius: 0.5rem
  box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03)
  color: white
  padding: 20px
  position: fixed
  max-width: 600px
  width: 100%
  bottom: 10px
  right: -650px
  z-index: 1000

  p.title, p span, p a
    font-weight: 600

  p
    span, a
      text-decoration: underline
      color: white
      cursor: pointer

.placeholder
  background: #dbdbdb

.list-group-item
  padding: 10px 15px !important

  .logo
    height: 40px
    width: 40px
    background:
      size: cover
      position: center
      margin-right: 20px

button, .btn
  outline: none

.btn:focus, .btn.focus
  box-shadow: none

.modal-container
  position: fixed
  top: 0
  right: 0
  left: 0
  bottom: 0
  z-index: -1
  transition: 0.3s z-index

  &--active
    z-index: 250
    transition: 0s z-index

  .modal-overlay
    position: fixed
    top: 0
    right: 0
    left: 0
    bottom: 0
    background: rgba(0, 0, 0, 0.4)

.custom-modal
  position: fixed
  top: 50%
  left: 50%
  transform: translate(-50%, -50%)
  max-width: 600px
  width: calc(100% - 30px)
  background: white
  border-radius: 5px
  max-height: 80vh

  &-position
    &--right
      max-height: 100vh
      height: 100%
      top: 0
      left: auto
      right: 0
      transform: translate(0, 0)
      border-radius: 0
      max-width: 300px

      .custom-modal-body
        height: 100%

  &-header
    padding: 15px
    border-bottom: 1px solid #cbcbcb

  &-body
    max-height: calc(80vh - 53px)
    padding: 15px
    overflow: auto !important
    background: white
    border-bottom:
      left-radius: 5px
      right-radius: 5px

    &--no-padding
      padding: 0

.card-table-title
  margin-bottom: 0

.btn-naked
  padding: 0 !important
  background: none !important
  border: none !important
  color: #807b7b !important
  outline: none !important
  transition: all 0.15s linear

  &:disabled
    color: #b9b9b9 !important

  &:not(:disabled):hover
    color: black !important

  + .btn-naked
    margin-left: 15px

.switch
  cursor: pointer

  + .switch
    margin-top: 10px

  &-placeholder
    border-radius: 20px
    height: 20px
    min-width: 38px
    background: #e4e4e4
    margin-right: 10px
    box-shadow: none

    span
      display: block
      height: 16px
      width: 16px
      border: 1px solid rgba(0, 0, 0, 0.25)
      border-radius: 50%
      background: #ffffff
      margin:
        top: 2px
        left: 2px
      transition: 0.1s all $ease-in-out-quad

    &--active
      background: #18375F

      span
        margin-left: 20px
        background: #fff

  p
    margin-bottom: 0

.form-control:focus
  box-shadow: none !important

.typeahead-container
  input
    border-top-right-radius: 0
    border-bottom-right-radius: 0

.radio-button
  cursor: pointer
  margin:
    top: .5rem
    bottom: .5rem

  &:not(:last-child)
    margin-right: 5px

  label
    margin:
      left: .5rem
      bottom: 0

// animations
.error-enter
  opacity: 0
  right: -650px

.error-enter-active, .error-enter-done
  opacity: 1
  right: 10px
  transition: all 200ms

.error-exit
  opacity: 1
  right: 10px

.error-exit-active
  opacity: 0
  right: -650px
  transition: all 200ms

.modal-enter
  opacity: 0
  transform: translate(-50%, -50%) scale(1.2)

.modal-enter-active, .modal-enter-done
  opacity: 1
  transform: translate(-50%, -50%) scale(1)
  transition: all 0.3s

.modal-exit
  opacity: 1
  transform: translate(-50%, -50%) scale(1)

.modal-exit-active
  opacity: 0
  margin-top: 50px
  transform: translate(-50%, -50%)
  transition: all 0.3s

.modal--right-enter
  opacity: 0
  right: -800px

.modal--right-enter-active, .modal--right-enter-done
  opacity: 1
  right: 0
  transition: right 0.2s $ease-in-out-quart, opacity 0.2s

.modal--right-exit
  opacity: 1
  right: 0

.modal--right-exit-active
  opacity: 0
  right: -800px
  transition: right 0.2s $ease-in-out-quart, 0.1s opacity 0.2s

.fade-enter
  opacity: 0

.fade-enter-active, .fade-enter-done
  opacity: 1
  transition: all 0.3s

.fade-exit
  opacity: 1

.fade-exit-active
  opacity: 0
  transition: all 0.3s

.rbt-token
  font-size: 12px
  background-color: #18375F
  border: 0
  border-radius: 50px
  color: #ffffff
  display: inline-block
  margin: 0 3px 3px 0
  padding: 1px 10px
  position: relative
  display: inline-flex
  align-items: center
  justify-content: space-between

  button
    margin-left: 5px
    padding-top: 3px

.rbt-input-multi
  cursor: text
  overflow: hidden
  position: relative
  height: auto
  min-height: calc(1.5em + 1rem + 2px)

.rbt-input-multi .rbt-input-wrapper
  margin-bottom: -4px
  margin-top: -1px
  overflow: hidden

.rbt-input-multi .rbt-input-hint-container
  display: inline-block

.environmental-info-title
  display: inline-block
  width: 250px
  font-weight: 600

.form-admin-other
  .divider
    border-top: 1px solid rgb(218, 222, 226)
    padding-top: 20px

  button + button
    margin-left: 10px

.rdw-editor-main
  border-radius: 2px
  border: 1px solid #F1F1F1
  padding: 10px

  .DraftEditor-root
    height: 150px

.public-DraftStyleDefault-block
  margin: 0

.css-1py7kdp-ToastContainer
  z-index: 1000
  top: 70px !important
  height: calc(100vh - 70px)

  div[role="button"]
    display: flex
    align-items: center
    justify-content: center

.css-tqwi0d-Icon
  background-color: #18375f !important

.css-1shexx9-Icon, .css-tqwi0d-Icon, .css-1afmoxs-Icon, .css-dew4vy-Icon
  display: flex
  align-items: center
  justify-content: center

.css-b6wk0d-Content
  display: flex
  align-items: center

.step-icon-container
  position: absolute
  bottom: 30px
  right: 25px

.rbt-menu.dropdown-menu
  li
    a
      padding: 0.375rem 8px
      text-overflow: ellipsis
      white-space: nowrap
      overflow: hidden

.list-group
  .list-group-item
    background: white

.btn, .form-control:not(textarea), .custom-select
  font-size: 0.8rem !important
  // border-radius: 100px
  padding: .375rem .900rem
  height: 38px

.card-header
  min-height: 57px
  padding-top: 0 !important
  padding-bottom: 0 !important

  &-wrapper
    min-height: 57px

    .card-table-title
      font:
        weight: 600
        size: 14px

  &-input
    position: relative
    flex: 1
    height: 57px

    input
      position: absolute
      background: none
      border: none
      padding-left: 25px
      outline: none
      width: 100%
      height: 100%

  button, .search
    height: 29px
    line-height: 29px
    padding: 0 .900rem

    svg
      margin-right: 5px
      height: 27px

    &:not(:first-of-type)
      margin-left: 7px

  &--filter
    margin: 0px -0.75rem 0 -1.5rem
    padding: 15px 1.5rem 15px
    border-top: 1px solid #edf2f9

    .custom-react-normal-select
      height: 32px
      border-radius: 32px
      padding: 0px 5px
      font-size: 13px

    .rbt
      &-aux
        position: absolute
        top: 0
        right: 10px

      .form-control
        height: 31px
        margin-right: 30px
        border-radius: 30px

        &.rbt-input-multi
          height: auto
          min-height: 31px
          padding:
            left: 5px
            right: 5px

          &--no-padding
            border-radius: 10px

      .rbt-aux
        position: absolute
        top: 0
        left: calc(100% - 50px)

    .rbt-token button
      padding-top: 0
      height: 26px

      span[aria-hidden="true"]
        height: 10px

    .row
      [class*="col-"]
        margin-top: 15px

    h5
      margin:
        bottom: 0
        right: 20px
      color: #acacac
      font-weight: 400

.btn + .btn
  margin-left: 0.375rem !important

  &.career-page-publish-button
    margin-left: 0 !important

.fixed-card-header
  min-height: 60px
  padding: 0 1.5rem
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05)

.candidate-list-item-container
  min-width: 260px

.card-body-load-more
  min-height: 60px

.card-body--list-item
  margin: 0

  li
    border-top: 1px solid #EDF2F9
    padding: .750rem 1.5rem

    &.active-item
      position: relative

      &:after
        content: ''
        position: absolute
        top: 0
        bottom: 0
        width: 5px
        background: $blue
        left: 0

    .custom-avatar
      margin-left: 10px

    .details
      flex: 1
      padding-left: 10px

      h5
        margin-bottom: 3px

      h6
        margin-bottom: 0

.candidate-list-container
  position: sticky
  top: 0
  min-width: 250px

  +xs
    position: static

  +sm
    position: static
    max-width: 100%
    flex: 0 0 100%

.candidate-details-container
  flex: 1
  margin-bottom: 0

  +xs
    margin-top: 20px

  +sm
    margin-top: 20px
    max-width: 100%
    flex: 0 0 100%

.card-body--candidate-view
  .buttons
    +xs
      margin-top: 20px

    +sm
      margin-top: 20px

  .nav-tabs .nav-link
    padding: 0.5rem 0.75rem

  .details-container
    margin-bottom: 15px

  .tags-container
    padding-bottom: 15px

    +sm
      flex-direction: column

  .also-applied-text
    margin-bottom: 10px

  .view-details
    p
      margin-bottom: 0
      font-size: 13px
      color: #272727

    div.details-container svg
      width: 15px

    input[type=date]
      border: none
      padding: 0
      background: none

.candidate-card-v2
  .tab-content
    margin-top: 20px
    padding:
      top: 20px
      bottom: 20px

    &--timeline
      position: relative
      padding: 0 50px

      +xs
        padding: 0 0 0 20px

      +sm
        padding: 0 0 0 20px

      span.line
        content: ''
        position: absolute
        top: 55px
        left: 47px
        width: 3px
        background: $primary-color

        +xs
          left: 17px

        +sm
          left: 17px

      .timeline-item
        background: $accent-light-color
        border-radius: 3px
        padding: 25px 50px 25px 40px
        position: relative
        min-height: 87px
        font-size: 14px
        color: $text-color

        p *, a
          word-break: break-all

        &:not(:first-of-type)
          margin-top: 25px

        &--icon
          height: 40px
          width: 40px
          background: $primary-color
          border-radius: 50%
          position: absolute
          left: -22px
          top: 20px

          svg
            width: 14px !important

        .timeline-date
          color: $text-color-secondary
          font-size: 13px
          position: relative

          span + span
            display: none
            position: absolute
            bottom: -30px
            white-space: nowrap
            left: 50%
            transform: translateX(-50%)
            background: $popover-gray-bg
            color: $popover-gray-text
            padding: 3px 10px
            border-radius: 3px

            &:before
              font-size: 12px
              content: ''
              position: absolute
              top: -5px
              left: 50%
              transform: translateX(-50%)
              width: 0
              height: 0
              border-left: 5px solid transparent
              border-right: 5px solid transparent
              border-bottom: 5px solid $popover-gray-bg

          &:hover
            span + span
              display: inline-block

        &-comment
          &-link
            margin-top: 5px

            span
              font-size: 13px
              color: $text-color
              margin-right: 4px

              &:hover
                color: $gray-700

        .application-history .table
          margin: 0

        pre
          font-family: normal
          overflow: hidden
          // width: 100%
          white-space: normal !important

          *
            white-space: normal !important

          br
            margin-top: 1rem

          a
            display: block

.clickable
  cursor: pointer

p
  margin-bottom: 0.5rem

.badge
  border-radius: .375rem !important
  font-size: 10px

form.badge-tags
  width: 115px

.badge-tags
  background: #f5f6f7
  border: 1px solid #dedbdb
  radius: 50px
  font-size: 13px
  padding: 2px 8px
  margin-right: 10px
  height: 25px
  margin-bottom: 0.5rem
  position: relative

  &-result
    background: white
    border: 1px solid rgba(18, 38, 63, 0.1)
    radius: 0.375rem
    position: absolute
    top: 26px
    left: 20px
    max-height: 200px
    overflow: auto
    z-index: 20

    li
      padding: 5px 10px
      cursor: pointer

      &:not(:first-of-type)
        border-top: 1px solid rgba(18, 38, 63, 0.1)

  &--input
    padding: 0

    button.btn-naked
      background: white !important
      padding: 0 7px !important
      height: 23px !important
      border-top-right-radius: 23px
      border-bottom-right-radius: 23px
      position: relative
      right: 1px
      display: flex
      align-items: center

      svg
        margin-bottom: 0

  button
    svg
      margin:
        bottom: -1px
        left: 4px

  span
    border-right: 1px solid #dedbdb
    padding: 0 4px 0 6px

  input
    height: 100%
    min-width: 65px
    background: white
    border: none
    outline: none
    padding-left: 6px

  .rbt
    height: 22px !important

  .rbt-input-hint-container
    height: 100%

    input.form-control
      border-radius: 0 !important
      padding-right: 0px
      margin-top: 1px

    .rbt-input-hint
      height: 100%
      padding-bottom: 14px

em
  font-style: italic !important

.dropdown-item
  font-size: 14px !important
  color: black !important

  &:hover
    background: darken(white, 5%)

.table-status-bar
  background: #eeeeee
  border-radius: 30px
  height: 13px
  position: relative
  overflow: hidden

  .bar-thumb
    position: absolute
    left: 0
    top: 0
    background: $success
    height: 100%

    &:before
      content: ''
      position: absolute
      border-left: 7px solid $success
      border-top: 7px solid transparent
      border-bottom: 7px solid transparent
      right: -5px
      top: 50%
      transform: translateY(-50%)

  .bar-wrapper
    position: relative
    top: -1px

  .bar-item
    height: 13px
    width: 20px

    &:not(:first-of-type)
      position: relative

      &:before, &::after
        content: ''
        position: absolute
        height: 10px
        width: 2px
        background: white

      &:before
        top: -2px
        transform: rotate(135deg)

      &:after
        bottom: -2px
        transform: rotate(45deg)

.card-header--primary
  background: $primary-color
  color: white

  .card-header-actions
    flex: 0 0

.checkmark
  height: 1rem
  width: 1rem
  margin-top: -2px
  border-radius: 50%
  stroke-width: 5
  stroke: #fff
  stroke-miterlimit: 10
  box-shadow: inset 0px 0px 0px $primary-color
  animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both

  &--circle
    stroke-dasharray: 166
    stroke-dashoffset: 166
    stroke-width: 2
    stroke-miterlimit: 10
    stroke: $primary-color
    fill: none
    animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards

  &--check
    transform-origin: 50% 50%
    stroke-dasharray: 48
    stroke-dashoffset: 48
    animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards

@keyframes stroke
  100%
    stroke-dashoffset: 0

@keyframes scale
  0%, 100%
    transform: none

  50%
    transform: scale3d(1.1, 1.1, 1)

@keyframes fill
  100%
    box-shadow: inset 0px 0px 0px 30px $primary-color

.pipeline-template-input
  .rbt-input-main.form-control.rbt-input
    border-top-right-radius: 0 !important
    border-bottom-right-radius: 0 !important

.card-handler
  position: absolute
  top: 0
  right: 0
  bottom: 0
  left: 0
  z-index: 2

.card-button
  position: relative
  z-index: 3

.custom-bg-dark
  background: #202123

.dropzone-text
  color: var(--text-color-secondary)
  text-transform: uppercase
  font-size: 32px
  letter-spacing: 5px

.google-map
  border-radius: 20px
  overflow: hidden

.search-input-loader
  position: absolute
  top: 50%
  transform: translateY(-50%)
  width: 30px
  left: -10px

// .btn-primary
//   border: $light-blue
//   background: $light-blue
//   color: #2e527a !important
//   font-weight: bold !important

//   &:hover
//     background: darken($light-blue, 10%)

.btn-rounded
  border-radius: 100px !important

.btn-light-green
  background-color: $white
  color: $light-green
  border-color: $light-green !important

  &:hover
    background-color: $light-green
    color: $white !important

.btn-light-gray
  background-color: $white !important
  color: $gray-900 !important
  border-color: #e3ebf6 !important

  &:hover
    border-color: #e6e6e6

a
  color: $blue !important

.btn-link
  color: $blue !important

.two-cols
  .row
    margin: 0 !important

::-ms-clear, ::-ms-reveal
  display: none

.color-white
  color: white !important

.nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link
  border-color: transparent transparent $blue

.career-page-input-error
  color: white

textarea::-webkit-input-placeholder
  color: rgba(0, 0, 0, 0.4)

textarea::-moz-placeholder
  color: rgba(0, 0, 0, 0.4)

textarea:-moz-placeholder
  color: rgba(0, 0, 0, 0.4)

textarea:-ms-input-placeholder
  color: rgba(0, 0, 0, 0.4)

textarea::placeholder
  color: rgba(0, 0, 0, 0.4)

.candidate-view-datepicker
  padding: 0 !important
  border: none !important
  height: auto !important

  &::-webkit-input-placeholder
    color: rgba(0, 0, 0, 0.4)
    font-weight: 400

  &:-ms-input-placeholder
    color: rgba(0, 0, 0, 0.4)
    font-weight: 400

  &::placeholder
    color: rgba(0, 0, 0, 0.4)
    font-weight: 400

  &:valid
    font-size: 14px !important

.content-editable-no-design[contenteditable="true"]
  white-space: nowrap
  overflow: hidden

.content-editable-no-design[contenteditable="true"] br
  display: none

.content-editable-no-design[contenteditable="true"] *
  display: inline
  white-space: nowrap

.react-datepicker-popper
  z-index: 1250

.__react_component_tooltip
  display: none !important

.small-title
  text-transform: uppercase
  font:
    size: 12px
    weight: 700
  letter-spacing: 1px

.client
  &-field
    &-date
      border-radius: 0.375rem !important

.pipeline-selectbox
  height: 38px
  width: 130px
  margin-right: 0.375rem !important
  z-index: 2
  position: relative

  .custom-react-select__control
    border-radius: 9999px !important

  .custom-react-select__control, .custom-react-select__value-container
    height: 38px
    border-radius: 100px
    padding:
      left: 10px
      right: 20px

  .custom-react-select__control
    background: $primary-color
    border-color: $primary-color
    box-shadow: none !important
    color: white
    padding-right: 5px

    .custom-react-select__single-value
      color: $text-color-light

    .custom-react-select__indicators .custom-react-select__indicator svg
      color: $white

    .custom-react-select__value-container
      height: 40px

  .custom-react-select__indicators
    height: 40px

.is-invalid
  .custom-react-select__control
    border-color: $danger !important

.input-normal-radius
  border-radius: 0.375rem !important

.select-option-avatar
  padding: 4px 10px
  cursor: pointer

  &:hover
    background: rgba(0, 0, 0, 0.05)

  .custom-avatar
    margin-right: 10px

.__react_component_tooltip.show
  display: inline-block !important
  position: fixed !important

.force-hidden
  div[id^="expand-collapse-tooltip-"]
    display: none !important

.application-history
  .question
    margin-right: 8px
    font-weight: 600

.badge-mention
  background: #b9daf4
  padding: 1px 4px
  border-radius: 4px
  cursor: pointer

pre
  margin: 0
  padding: 0
  border: 0
  font-size: 100%
  font: inherit
  vertical-align: baseline

.ts-custom-table
  border-collapse: collapse

  thead tr th
    border-width: 0

  .ts-table-sort-icon
    height: 14px
    width: 14px

  .custom-avatar, .ts-table-badge
    margin-right: 10px

.ts-custom-table-header
  .custom-react-select__value-container
    padding: 1px 8px !important

  .custom-react-select__control
    min-height: 32px
    cursor: pointer
    outline: none
    border-width: 1px !important
    box-shadow: none !important

    &:hover, &:focus, &:active
      box-shadow: 0 0 23px rgba(0, 0, 0, 0.07), 0 0 4px rgba(0, 0, 0, 0.1) !important

  .custom-react-select__control--menu-is-open
    box-shadow: 0 0 12px rgba(0, 0, 0, 0.08) !important

.form-validator
  .ql-toolbar
    border-top-left-radius: 0.375rem
    border-top-right-radius: 0.375rem
    border-color: #D2DDEC

  .ql-container
    border-bottom-left-radius: 0.375rem
    border-bottom-right-radius: 0.375rem
    border:
      top: none
      color: #D2DDEC

.badge
  height: 18px
  padding: 0 0.475rem
  line-height: 18px
  border-radius: .375rem !important
  font-size: 10px

  &-success
    color: #00d97e
    background-color: #ccf7e5

  &-danger
    color: #e63757
    background-color: #fad7dd

.star-rating
  svg.clickable:hover
    color: #FFCC00

.btn.btn-success
  color: white !important

.intercom-lightweight-app-launcher, .intercom-launcher-frame
  transition: all 0.15s linear

.position-page
  .main-content
    padding-bottom: 130px !important

  .intercom-lightweight-app-launcher,
  // button is inside iframe but wrapper can be targeted
  .intercom-app div:nth-child(2),
  .intercom-app div:nth-child(3)
    bottom: 85px

  .intercom-lightweight-app, #intercom-container
    .intercom-launcher-frame
      bottom: 85px

    .intercom-messenger-frame
      bottom: 160px

  .intercom-namespace iframe
    bottom: 130px !important

  .intercom-app iframe:nth-child(1)
    bottom: 0 !important

.filter-modal-active
  overflow: hidden

  .intercom-lightweight-app-launcher, .intercom-launcher-frame
    right: 320px !important

.notification-container
  padding: 70px 15px 15px !important
  z-index: 199

  &-empty
    z-index: -1

.transition-group-container
  flex: 1

.popover
  max-width: 15em !important
  border: none
  z-index: 2147483005 !important
  box-shadow: 0 0 20px rgba(0,0,0,.1)
  transition: box-shadow .15s linear

  &:hover
    box-shadow: 0 0 30px rgba(0,0,0,.22)

.input-group-append
  height: 38px

  span
    font-size: 12px

.share-popover
  .popover
    max-width: 35em !important
    width: 100%

    .popover-inner
      max-width: none

.options-popover
  .popover
    padding: 0
    max-width: 100% !important

ul.list-group > div > div
  border: 1px solid #E3EBF6

  &:first-child
    border-top-right-radius: 0.375rem
    border-top-left-radius: 0.375rem
    overflow: hidden

  &:last-child
    border-bottom-right-radius: 0.375rem
    border-bottom-left-radius: 0.375rem
    overflow: hidden

  &:not(:first-child)
    border-top: none

  li
    border: none
    radius: 0 !important

.candidates-options-popover
  .popover
    top: 10px !important
    max-width: none !important
    z-index: 1000 !important

  &-select
    width: 150px

.popover
  z-index: 1000

  &-inner .popover-body
    button.btn
      height: 30px
      line-height: 29px
      padding: 0 .700rem
      display: flex
      align-items: center
      margin-left: 0 !important

.popper-screening-questions
  .popover
    max-width: none !important
    left: -7px !important

.bs-popover-left > .arrow::before, .bs-popover-auto[x-placement^="left"] > .arrow::before
  border-left-color: transparent !important

.bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^="bottom"] > .arrow::before
  border-bottom-color: transparent !important

.bs-popover-right > .arrow::before, .bs-popover-auto[x-placement^="right"] > .arrow::before
  border-right-color: transparent !important

.bs-popover-top > .arrow::before, .bs-popover-auto[x-placement^="top"] > .arrow::before
  border-top-color: transparent !important

span[data-toggle="dropdown"]
  display: inline-flex

.form-check-input
  position: static
  margin: 0 !important

.form-control[readonly]
  background: #f5f5f5 !important

.card-header--filter-modal
  position: fixed
  z-index: 250
  height: calc(100vh - 70px)
  bottom: 0
  right: 0
  width: 300px
  background: white
  box-shadow: 0px 5px 40px rgba(0, 0, 0, 0.16)
  // transition: all 0.15s linear

  h5
    @apply mb-2

  +xs
    width: 100%
    z-index: 201
    height: auto
    top: 0

  &-container
    position: relative
    padding: 0 1.5rem 1.5rem
    height: 100%
    overflow:
      x: hidden
      y: auto

  &-button-container
    background: white
    position: sticky
    top: 0
    z-index: 3
    padding: 1.5rem
    margin:
      left: -1.5rem
      right: -1.5rem

  &-button
    height: 30px
    padding: .375rem .750rem

.candidate-card-view
  overflow-y: auto

.input-loader
  position: absolute
  top: 0
  right: 0
  bottom: 0
  left: 0

.ql-disabled .ql-editor
  background: #f2f2f2

iframe.ql-video
  width: 100%
  display: block

.input-group-text
  height: 38px
  background: #D2DDEC
  color: var(--text-color)
  font-size: 0.8rem !important

.input-is-loading
  .custom-react-select__indicators
    display: none

div[class^="Snackbar_snackbar-wrapper-bottom-center__"], div[class*="Snackbar_snackbar-wrapper-bottom-center__"]
  z-index: 1000

.ql-editor p
  margin-bottom: 1rem !important
  font-family: "Cerebri Sans", sans-serif !important

.sub-label
  font-size: 12px
  color: #848FA5

.ts-card-heading
  font-size: 18px
  font-weight: 500
  text-align: center

.ts-card-text
  font-size: 15px

.position-form-holder span.input-group-text
  background: #d2ddec !important
  color: var(--text-color) !important

.input-range__label
  display: none

.input-range__slider-container
  z-index: 100

.input-range__slider
  background: #18375f !important

.input-range__track
  background: #f4f4f5 !important

  &--active
    background: #ff9f1c !important

.avatar-image
  svg
    left: 7.6em
    box-sizing: content-box !important

    &:hover
      background-color: #e5e7eb
      color: #dc2626
      outline: none

.position-team-member-tooltip
  padding: 0 !important

.form-template-with-variable
  .ql-toolbar.ql-snow .ql-formats:last-child .ql-picker-label
    svg
      display: none

.career-page-information-item-action
  .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options
    background-color: #5d646b

.custom-modal-wrapper
  .ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-options
    max-width: 13.5rem

  span.ql-picker-item
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis

.ql-snow .ql-picker-options .ql-picker-item
  white-space: normal
  width: 300px

span.ql-picker-item
  svg
    height: 1rem
    width: 1rem

.custom-file-label, .custom-file-label::after
  color: #c5cfdd !important

.title-strikethrough
  text-align: center
  color: var(--text-color-secondary)
  margin-bottom: 20px
  margin-top: 20px
  position: relative

  &:before
    content: ''
    position: absolute
    height: 1px
    width: 100%
    background: var(--text-color-secondary)
    left: 0
    top: 11px

  span
    position: relative
    background: white
    z-index: 1
    padding: 0 10px
    font:
      size: 14px
      weight: 100
    text-transform: uppercase

// Fix for Tailwind issue with collapse styling
.collapse.show
  visibility: visible !important

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus
  -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important

.rc-dropdown
  font-family: "Cerebri Sans", sans-serif !important
