@import '../var'
@import '../mixins'

.candidates-documents
  margin-top: -20px
  
  .document
    margin:
      right: 20px
      top: 20px
    width: 200px
    height: 250px
    background: #f2f2f2
    border: 1px solid #e8e8e8
      radius: 5px
    padding: 10px

    &--light
      background: #fcfcfc

    &-header span
      font-size: 14px

      svg
        width: 9px
        margin-right: 5px

  .buttons
    button.btn-naked
      margin-left: 8px

  .plus-icon
    padding-bottom: 20px
    position: relative

    input
      position: absolute
      top: 0
      right: 0
      bottom: 0
      left: 0
      opacity: 0

  .ios-document
    margin: auto
    background: #f2f2f2
    border: 1px solid #e8e8e8

    canvas
      max-width: 100%
      height: auto !important