@import './var'

.order-list
  &-products
    margin: 
      top: 20px
      bottom: 20px

    &-item
      padding: 
        top: 20px
        bottom: 30px
      border-bottom: 1px solid #EDF2F9
      
      &:first-child 
        padding-top: 0

      &:last-child
        border-bottom: 2px solid #cdcdcd

  &-publish-wrapper
    margin: 
      top: 1.5rem

    &-submit
      flex-direction: column

    .form-group 
      margin-bottom: 0

@media (max-width: $breakpoint-lg)
  .order-list-publish-wrapper
    flex-direction: column !important
    align-items: flex-start !important

    &-submit 
      width: auto !important
      margin-top: 10px 



      