.ts-divider
  display: flex
  align-items: center
  justify-content: space-between
  position: relative

  h3 
    text-transform: uppercase
    font-weight: 400
    font-size: 12px
    margin-bottom: 0
    margin-right: 15px
    color: #c3c3c3

  &:after 
    content: ''
    height: 1px
    flex: 1
    background: #d0d0d0
   