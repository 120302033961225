.google-places-autocomplete
  position: relative

  .google-places-autocomplete__suggestions-container
    position: absolute
    background: white
    width: 100%
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1)
    transition: box-shadow 0.15s linear
    z-index: 999
    margin-top: 5px
    border-radius: 0.375rem

    &:hover
      box-shadow: 0 0 30px rgba(0, 0, 0, 0.22)

    .google-places-autocomplete__suggestion 
      padding: 10px
      cursor: pointer
      font-size: 14px

      &:not(:first-of-type)
        border-top: 1px solid #f3f3f3

      &:hover
        background: #f2f2f2
