@import './mixins'

.product
  width: 300px
  border: 1px solid #e2e2e2

  &-masonry
    margin: 0 15px

  .card-body
    padding: 35px 1.5em

  *
    text-align: center
    max-width: 100%

  &-promoted
    margin: 0 15px

    .card-body
      height: 500px
  
    .product-description
      max-height: 160px
      overflow: hidden
    
    .searchable-select li 
      text-align: left

  &-title
    font:
      size: 22px
      weight: 700
    margin-bottom: 20px

  &-description
    margin-bottom: 35px

  &-price-expiration
    .product-price
      margin-right: 25px

    span
      margin-left: 8px
      position: relative
      top: 1px

.product-button
  padding: 5px 15px 4px !important
  height: auto !important
  line-height: 30px !important
  margin-top: 20px !important
  border-color: #34d470 !important
  color: #34d470 !important
  line-height: 25px !important

  &:hover
    background: #34d470 !important
    color: white !important

.product-icons
  margin-top: 20px
  width: auto

  &-wrapper
    display: inline-flex
    flex-direction: column
  
  p
    display: inline-flex
    align-items: center
    margin:
      top: 5px
      bottom: 0

    img
      order: 0
      width: 14px
      height: 14px
      margin-right: 7px