.ts-paginator
  @apply flex items-center justify-center w-full mb-0
  height: 40px

  li
    height: 100%
    @apply text-center cursor-pointer flex items-center

    &.previous, &.next
      display: none !important

    a
      width: 28px
      height: 28px
      @apply text-xs text-center inline-block  mx-2
      line-height: 28px

      svg
        @apply transition stroke-current text-gray-600 hover:text-primary

      &.ts-paginator-item-active
        @apply rounded-md bg-primary
        color: white !important

    &:first-of-type, &:last-of-type
      @apply flex-grow

      a
        @apply flex items-center justify-center

    &:last-of-type 
      @apply flex justify-end

    &.disabled
      a svg
        @apply text-gray-300