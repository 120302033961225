.select-circular-loader
  margin-top: -1px
  margin-right: 3px

.input-box
  min-height: 38px

.select-options
  max-height: 300px

  p
    font-family: "Cerebri Sans", sans-serif !important

.invalid
  border-color: #f8bac3 !important