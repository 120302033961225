@import '../theme.scss'

.ql-dark
  margin-bottom: 1.375rem
  border:
    width: 1px
    style: solid
    color: var(--gray-badge)
    radius: 0.375rem

  .ql-snow
    border-radius: 5px 5px 0 0

    .ql-formats
      display: inherit

    .ql-formats:after
      display: none
      content: none

  .ql-container
    height: auto
    border: none

  .ql-editor
    height: 150px
    color: var(--text-color-secondary)
    background-color: white
    border: none
    border-radius: 0 0 5px 5px !important

    &::before
      color: rgba(255, 255, 255, 0.6)

    &::-webkit-scrollbar-thumb
      background: rgba($primary-color, 0.8)

    &::-webkit-scrollbar-track
      background: rgba($primary-color, 0.2)

  .ql-toolbar
    background-color: var(--accent-light-color)
    border: none
    bottom: 1px solid var(--gray-badge)

    button, span
      background-color: var(--accent-light-color)
      color: var(--text-color-secondary) !important

      .ql-stroke
        stroke: var(--text-color-secondary) !important

    .ql-picker
      background-color: #5d646b
      width: 105px !important
      height: auto !important
      margin:
        top: 5px
        right: 4px
      border-radius: 6px

      &-label
        color: var(--text-color-secondary) !important
        padding: 2px 5px

        &:before
          margin-right: 4px

        .ql-stroke
          stroke: var(--text-color-secondary) !important

    .ql-fill
      fill: var(--text-color-secondary) !important

    .ql-align
      width: 28px !important
      height: 24px !important
      margin: 0 !important
      background-color: var(--accent-light-color) !important

      .ql-picker-label
        padding: 0px 5px

      .ql-picker-options
        background-color: var(--accent-light-color) !important

    .ql-formats
      padding-left: 0px
      padding-right: 0px
      margin-left: 0px
