// 
// buttons.scss
// Extended from Bootstrap
//


//
// Dashkit ===================================
//

// Button white

.btn-white {
  border-color: $gray-300;

  @include hover-focus {
    background-color: $gray-100;
    border-color: $gray-400;
  }
}

.btn-primary {
  background-color: $primary-color;
  border-color: $primary-color;
  color: $text-color-light;

  @include hover-focus {
    box-shadow: 0 1px 2px rgba(0,0,0,.05);
    color: $text-color-light;
  }
}

.btn-group-toggle .btn-white:not(:disabled):not(.disabled):active, 
.btn-group-toggle .btn-white:not(:disabled):not(.disabled).active {
  background-color: $input-bg;
  border-color: $input-focus-border-color;
  color: $primary;
}

.btn-group-toggle .btn-white:focus,
.btn-group-toggle .btn-white.focus {
  box-shadow: none;
}

// // danger
// .btn-danger {
//   color: $black !important;
//   border-color: $black !important;
//   background-color: $error-color;

//   &:hover {
//     background-color: $button-danger-hover;
//   }
// }

.btn-outline-light {
  background-color: transparent;
  border-color: $black;
  color: $black;

  &:hover:not(:disabled), &:hover:not(.disabled) {
    border-color: $black;
  }

  &.disabled, &:disabled {
    border-color: $black;
    color: $black !important;
  }
}

.btn-outline-light-2 {
  background-color: transparent;
  border-color: $black;
  color: $black;

  &:hover:not(:disabled), &:hover:not(.disabled) {
    border-color: $black;
    background: #FF31341A;
  }

  &.disabled, &:disabled {
    border-color: $black;
    color: $black !important;
  }
}

.btn-outline-danger {
  border-color: $error-color;
  color: $error-color;

  &:hover:not(:disabled) {
    border-color: transparent;
    background-color: $error-color;
    color: #ffffff;
  }

  &.disabled, &:disabled {
    border-color: $error-color;
    color: $error-color !important;
  }
}

.btn-outline-success {
  border-color: $success-color;
  color: $black;

  &:hover:not(:disabled) {
    border-color: transparent;
    background-color: $success-color;
    color: $black;
  }

  &.disabled, &:disabled {
    border-color: $success-color;
    color: $black !important;
  }
}

.btn-outline-primary {
  border-color: $primary-color;
  color: $primary-color;

  &:hover:not(:disabled) {
    border-color: transparent;
    background-color: $primary-color;
    color: #ffffff;
  }

  &.disabled, &:disabled {
    border-color: $primary-color;
    color: $primary-color !important;
  }
}



// Button rounded
//
// Creates circle button variations

.btn-rounded-circle {
  width: calc(1em * #{$btn-line-height} + #{$input-btn-padding-y * 2 } + #{$btn-border-width} * 2);
  padding-left: 0;
  padding-right: 0;
  border-radius: 50%;
}
.btn-rounded-circle.btn-lg {
  width: calc(1em * #{$btn-line-height-lg} + #{$input-btn-padding-y-lg * 2 } + #{$btn-border-width} * 2);
}
.btn-rounded-circle.btn-sm {
  width: calc(1em * #{$btn-line-height-sm} + #{$input-btn-padding-y-sm * 2 } + #{$btn-border-width} * 2);
}


// Button group
//
// Prevent buttons from jittering on hover

.btn-group .btn + .btn {
  margin-left: 0;
}