$md-break-size: 1330px

.ql-custom-dropdown
  width: 20px
  

.ql-custom-dropdown 
  > span.ql-picker-options
    border-radius: 6px
    max-height: 150px
    overflow-y: auto
    left: 50%
    right: auto
    transform: translateX(-65%)
    max-width: 300px
    overflow-x: hidden
    
    > span.ql-picker-item::before
      content: attr(data-label)

.ql-custom-dropdown-label
  position: absolute
  padding-top: 2px