@import './var'
@import './mixins'
@import './theme'

.position-form-wysiwyg
  .ql-editor
    min-height: 630px
    max-height: 630px
    overflow-y: auto

    +xs
      min-height: 150px
      max-height: 150px

.position-form-holder .form-group
  .card.position-form-draggable-card
    margin-bottom: 0.5rem

    .card-header
      min-height: auto !important
      padding: 0.5rem 0.75rem !important

  .custom-react-select__control.custom-react-select__control
    min-height: 38px !important
    border-radius: 0.375rem !important

    .custom-react-select__indicator
      position: relative
      top: 3px

  .custom-react-select__menu
    border-bottom-right-radius: 0.375rem !important
    border-bottom-left-radius: 0.375rem !important

  .custom-wysiwyg, .ql-snow
    border-radius: 0.375rem !important
    border: 1px solid $border-color

    .ql-header
      min-width: 110px
      width: auto !important

      .ql-picker-label
        padding-right: 20px

    .ql-container, .ql-toolbar, .ql-editor
      border: none
      radius: 0 !important

    .ql-toolbar
      border-bottom: 1px solid $border-color

.position-form-icon-option
  padding: 4px 10px
  cursor: pointer

  &:hover
    background: rgba(0, 0, 0, 0.05)

  svg
    display: inline-block !important
    width: 20px !important
    margin-right: 5px

.position-form-draggable-card
  .card-header > div.controls
    position: relative
    height: 22px

    .control-draggable
      position: absolute
      top: 0
      left: 0
      bottom: 0
      right: 0

    svg
      position: absolute
      right: 0
      top: 4px

.deep-link
  display: flex
  align-items: center
  justify-content: center

  span
    margin-right: 4px

.position-form-template-wysiwyg
  .ql-editor
    min-height: 430px

    +xs
      min-height: 150px

.form-position-next-button,
.form-position-back-button
  border-radius: 100px !important

@media (max-width: $breakpoint-lg)
  .deep-link
    justify-content: flex-start
    align-items: flex-start
    flex-direction: column
